import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import HelloSign from 'hellosign-embedded';
import { useLazyQuery, useMutation } from '@apollo/client';
import { get } from 'lodash';
import { Button } from '@vartanainc/design-system';

import { CrossButton } from '../../../../components/Button/CrossButton';
import ActionRequired from '../ActionRequired';
import Loader from '../../../../components/Loader';
import { DocusignCloseModal } from '../../../../components/CloseModal/DocusignCloseModal';
import {
  GET_VENDOR_SIGNATURE_URL,
  GET_ORDER_BY_NUMBER,
  UPDATE_VENDOR_SIGNER_DETAILS,
} from '../../../../graphql/queries/order';
import { showErrorToast, reportError } from '../../../../utils/helpers';
import { OrderShowContext } from '../../../../context/OrderContext';
import { SELLER_TYPE } from '../../../../constants/common.constants';

const docusignStyles = {
  branding: {
    primaryButton: {
      backgroundColor: '#054BC7',
      color: '#fff',
    },
  },
  signingNavigationButton: {
    position: 'bottom-right',
  },
};

function VendorSignatureRequired({
  orderNumber,
  testMode,
  updatedAt,
  onSigned,
  isTrack,
}) {
  const isTestMode = process.env.REACT_APP_NODE_ENV === 'production' && testMode;
  const [isHelloSignFormOpen, setIsHelloSignFormOpen] = useState(false);
  const [isDocusign, setIsDocusign] = useState(false);
  const [docusignSignatureUrl, setDocusignSignatureUrl] = useState('');
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNextDocLoading, setShowNextDocLoading] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [secondsPassed, setSecondsPassed] = useState(0);
  const { refetchOrderData } = useContext(OrderShowContext);
  // A flag to determine if a call has already been sent to BE
  const signerDetailUpdated = useRef(false);

  const [updateVendorSignerDetails] = useMutation(UPDATE_VENDOR_SIGNER_DETAILS);

  const { helloSignClient, skipDomainVerification } = useMemo(() => {
    return {
      helloSignClient: new HelloSign({
        clientId: isTestMode
          ? process.env.REACT_APP_HELLOSIGN_SANDBOX_CLIENT_ID
          : process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
      }),
      skipDomainVerification: isTestMode
        ? true
        : process.env.REACT_APP_HELLOSIGN_PRODUCTION_MODE !== 'true',
      testMode: isTestMode,
    };
  }, [isTestMode]);

  const [getVendorSignatureUrl] = useLazyQuery(GET_VENDOR_SIGNATURE_URL, {
    variables: {
      number: orderNumber,
    },
    onCompleted: (data) => {
      const signatureUrl = get(data, 'order.vendorSignatureUrl', null);
      if (signatureUrl) {
        if (signatureUrl.includes('docusign')) {
          setDocusignSignatureUrl(signatureUrl);
          setIsDocusign(true);
        } else {
          setIsHelloSignFormOpen(true);
          helloSignClient.open(signatureUrl, {
            allowCancel: true,
            skipDomainVerification,
            testMode: isTestMode,
          });
        }
      } else {
        showErrorToast();
      }
    },
  });

  const [getOrder] = useLazyQuery(GET_ORDER_BY_NUMBER, {
    variables: {
      number: orderNumber,
    },
    onCompleted: (data) => {
      if (data?.order?.state !== 'vendor_signature') {
        clearInterval(intervalId);
        setIsDocusign(false);
        setShowCloseModal(false);
        onSigned();
        refetchOrderData();
      } else if (secondsPassed >= 15 && data?.order?.state === 'vendor_signature') {
        clearInterval();
        setIsDocusign(false);
        setShowCloseModal(false);
        onSigned();
      }
    },
  });

  const openSignatureForm = () => {
    setIsBtnClicked(true);
    getVendorSignatureUrl();
  };

  const signDocusignDocument = useCallback(
    async (urls, urlIndex) => {
      if (urlIndex >= urls.length) {
        // All documents signed polling to see if data state has been updated
        const id = setInterval(() => {
          setSecondsPassed((prev) => prev + 3); // Increment secondsPassed by 3 seconds
          getOrder();
        }, 3000);

        setIntervalId(id);

        if (!signerDetailUpdated.current) {
          // set signerDetailUpdated true to avoid multiple backend calls
          signerDetailUpdated.current = true;
          await updateVendorSignerDetails({
            variables: {
              orderNumber,
              signatureEntity: SELLER_TYPE.VENDOR,
            },
          });
        }

        return;
      }

      setLoading(true);
      if (urlIndex > 0) {
        setShowNextDocLoading(true);
      }

      window.DocuSign.loadDocuSign(process.env.REACT_APP_DOCUSIGN_API_KEY)
        .then((docusign) => {
          let hasSessionEnded = false;
          const signing = docusign.signing({
            url: urls[urlIndex],
            displayFormat: 'focused',
            style: docusignStyles,
          });

          signing.on('ready', () => {
            // UI is rendered
            setLoading(false);
            setShowNextDocLoading(false);
          });

          signing.on('sessionEnd', (event) => {
            // session end callback
            if (!hasSessionEnded) {
              if (
                event.sessionEndType === 'signing_complete' ||
                event.sessionEndType === 'viewing_complete'
              ) {
                // Move on to the next document
                setLoading(true);
                signDocusignDocument(urls, urlIndex + 1);
              } else {
                // in all other cases cancel, decline, exception, fax_pending,
                // session_timeout, ttl_expired close docusign
                setShowCloseModal(false);
                setIsDocusign(false);
              }
            }
            hasSessionEnded = true;
          });

          signing.mount('#docusign');
        })
        .catch((e) => {
          reportError(`[DOCUSIGN ERROR]: ${e.message}`);
          showErrorToast();
        });
    },
    [getOrder, orderNumber, updateVendorSignerDetails]
  );

  useEffect(() => {
    if (isHelloSignFormOpen) {
      helloSignClient.on('sign', () => {
        setIsHelloSignFormOpen(false);
        helloSignClient.close();
        onSigned();
      });
      helloSignClient.on('close', () => {
        setIsHelloSignFormOpen(false);
      });
    }
  }, [helloSignClient, isHelloSignFormOpen, onSigned]);

  useEffect(() => {
    if (isDocusign) {
      const urls = JSON.parse(docusignSignatureUrl);
      signDocusignDocument(urls, 0);
    }
  }, [isDocusign, signDocusignDocument, docusignSignatureUrl]);

  useEffect(() => {
    if (isDocusign || isHelloSignFormOpen) {
      setIsBtnClicked(false);
    }
  }, [isDocusign, isHelloSignFormOpen]);

  return (
    <>
      {isTrack ? (
        <Button
          onClick={() => openSignatureForm()}
          size="x-small"
          backgroundColor="primary"
          variant="contained"
          id="progen-cta"
          className="active:bg-[#21438a33]"
        >
          Review and sign
        </Button>
      ) : (
        <ActionRequired
          title="Signature required"
          message="Please review and sign agreements for this order."
          secondaryMessage={updatedAt}
          onPrimaryClick={() => openSignatureForm()}
          primaryButtonText="Review and sign"
        />
      )}
      {isBtnClicked && (
        <div className="fixed bottom-0 left-0 top-0 right-0 bg-vartana-gray-70/80 z-[1] flex items-center justify-center">
          <Loader className="w-10 h-10" />
        </div>
      )}
      {isDocusign && (
        <div className="fixed bottom-0 left-0 top-0 right-0 bg-vartana-gray-70/80 flex justify-center z-[1]">
          <div className="bg-white h-full w-full md:h-[86%] md:w-[86%] md:rounded-xl flex flex-col md:mt-[3.25rem]">
            <div className="w-full flex justify-end p-2">
              <CrossButton
                onClick={() => setShowCloseModal(true)}
                className="w-6 h-6 relative top-0 right-0"
              />
            </div>
            <div className="px-4 pb-4 md:px-10 md:pb-10 h-inherit flex-1 relative">
              <p className="text-vartana-black-100 text-xl md:text-2xl font-bold mb-4 md:mb-6 text-left	">
                Review and sign documents
              </p>
              <div id="docusign" className="h-[calc(100%-2.5rem)] w-full relative" />
              {loading && (
                <div className="h-[calc(100%-2.5rem)] w-full absolute bottom-0 left-0 bg-white md:rounded-xl flex items-center flex-col">
                  <Loader className="w-10 h-10 mt-60" />
                  {showNextDocLoading && (
                    <p className="text-sm font-semibold text-vartana-gray-60 mt-6 w-full max-w-[11.875rem] text-center">
                      Please wait while we load the next document.
                    </p>
                  )}
                </div>
              )}
            </div>
            {showCloseModal && (
              <DocusignCloseModal
                onCloseClick={() => setShowCloseModal(false)}
                onConfirmClick={() => {
                  setShowCloseModal(false);
                  setIsDocusign(false);
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

VendorSignatureRequired.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  testMode: PropTypes.bool.isRequired,
  updatedAt: PropTypes.string.isRequired,
  onSigned: PropTypes.func.isRequired,
  isTrack: PropTypes.bool.isRequired,
};

export default VendorSignatureRequired;
