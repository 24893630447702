import { ReactElement } from 'react';
import { Typography } from '@vartanainc/design-system';

import { labelValueField, SIGNER_ENTITIES } from '../../../constants/common.constants';
import { formatCapital } from '../../../utils/helpers';

interface SignerProps {
  signatureEntity: string;
  signatureOrder: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  label: string;
  jobTitle: string;
}

const Signer = ({
  signatureEntity,
  signatureOrder,
  firstName,
  lastName,
  email,
  phone,
  label,
  jobTitle,
}: SignerProps): ReactElement => {
  // authorized signer would always have signature order 1 and have buyer entity
  const authorizedSignerOrder = 1;
  const isAuthorizedSigner =
    signatureEntity === SIGNER_ENTITIES.BUYER && signatureOrder === authorizedSignerOrder;
  return (
    <div>
      {/* we shouldnt show signer title for authorized signers as it's rendered via card title */}
      {!isAuthorizedSigner && (
        <div className="mt-5 mb-4">
          <Typography variant="heading16" bold color="color-black-100">
            {label}
          </Typography>
        </div>
      )}
      <div className="grid grid-cols-2 gap-6 relative">
        {labelValueField('First name', formatCapital(firstName || '--'))}
        {labelValueField('Last name', formatCapital(lastName || '--'))}
        {labelValueField('Email', email || '--')}
        {labelValueField('Phone', phone || '--')}
        {labelValueField('Job title', jobTitle || '--')}
      </div>
    </div>
  );
};

export default Signer;
