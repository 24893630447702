import { isEmpty, isNaN, omit } from 'lodash';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import { formattedTermDuration } from '../../api/utils';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  orderType,
  paymentFrequencyLabels,
  paymentOptions,
  paymentTermLabels,
  requiredPhoneFieldValidation,
  SIGNER_ENTITIES,
  zipSchema,
} from '../../constants/common.constants';
import { TEXT_CONSTANTS } from '../../constants/ui/text.constants';
import { nameSchema } from '../../formikSchema/commonSchema';

export const ALLOWED_STATES_TO_EXPIRE = [
  'approval_required',
  'requires_approval',
  'pending',
  'compliance_review',
  'credit_review',
  'ready_to_checkout',
  'checkout',
  'payment_options',
  'payment_method',
  'personal_guarantee',
  'verification',
  'signatures',
];

// A VU is allowed to add a maximum of 5 proposal options when creating an order
export const MAX_ALLOWED_PROPOSALS = 5;

// part of the error message recieved from the BE used to check error type
export const TCV_BELOW_APPROVED_ERROR = 'is below approved';

const ORDER_FORM_FIELD_ERROR_MESSAGE = 'Please upload a PDF document';

export const PRE_QUALIFIED_OFFER_LABEL = 'Pre-qualified offer';

export const MAX_INTEREST_RATE_OPTIONS_FOR_RADIO_MODE = 4;

export const MAX_ALLOWED_TCV = 9999999;

export const ADD_CUSTOMER_OPTION_LABEL = 'Add customer';
export const ADD_CUSTOMER_OPTION_VALUE = 'create';

export const ORDER_CREATION_PLACEHOLDER_EMPTY_TEXT =
  'Start typing to add or select a customer';
export const ORDER_CREATION_PLACEHOLDER_FILLED_TEXT = 'Add or select a customer to begin';

export const ORDER_CREATION_SUCCESS_MESSAGE =
  'We are processing your order and will send the documents to your customer shortly.';

// debounce time for search authorized signer call 500ms
export const SEARCH_AUTHORIZED_SIGNER_DEBOUNCE_TIME = 500;

export const SIGNER_JOB_TITLE_VALIDATION_DEBOUNCE_TIME = 100;

export const SIGNER_FIELDS_TO_OMIT = ['description', 'label', 'title', 'message'];

export const SIGNATURE_ORDER_PAYLOAD = {
  amount: 0,
  billingFrequency: null,
  paymentTerm: 0,
  term: null,
  blindDiscount: null,
  dollarBlindDiscount: 0,
  percentageBlindDiscount: 0,
  spiffRate: 0,
  startDate: new Date(),
  endDate: new Date(),
};

export const isNullEmptyOrUndefined = (value) => {
  return value === undefined || value === '' || value === null;
};

export const orderScreenRegex = {
  percentOrDollar: /[%$]/g,
};

export const initialProposalObj = {
  key: '',
  title: '',
  amount: '',
  orderType: null,
  startDate: null,
  endDate: null,
  contractLength: null,
  paymentFrequency: null,
  paymentTerm: null,
  vartanaFinancing: false,
  orderForm: null,
  subsidy: '',
  spiffRate: '',
  isDollar: false,
  maxSubsidyAllowed: '',
  dollarMaxSubsidy: '',
  maxSubsidyPercentage: '',
  reviewAndSignDocs: null,
  interestRate: null,
};

export const initialOrderFormValues = {
  companyNumber: '',
  companyName: '',
  message: '',
  proposals: [initialProposalObj],
  authorizedSigner: {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    phone: '',
  },
};

export const initialResellerFormValues = {
  resellers: [
    {
      uuid: uuidv4(),
      number: '',
      companyName: '',
      email: '',
      firstName: '',
      lastName: '',
    },
  ],
  payoutInformation: '',
  payoutRecipients: [],
  isResellerOrder: false,
};

export const resellerFormSchema = Yup.object().shape({
  isResellerOrder: Yup.boolean().oneOf([true, false]).isRequired,
  resellers: Yup.array().when('isResellerOrder', {
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          uuid: Yup.string(),
          number: Yup.string(),
          companyName: Yup.string().required('This field is required'),
          email: Yup.string().email('Invalid email').required('This field is required'),
          firstName: Yup.string()
            .required('This field is required')
            .matches(
              commonRegex.ALPHABETS_AND_SINGLE_SPACE,
              FIELD_INVALID_MESSAGES.firstName
            ),
          lastName: Yup.string()
            .required('This field is required')
            .matches(
              commonRegex.ALPHABETS_AND_SINGLE_SPACE,
              FIELD_INVALID_MESSAGES.lastName
            ),
        })
      )
      .unique('companyName', 'Please enter unique company names for all resellers.')
      .unique('email', 'Please enter unique email IDs for all resellers.'),
    otherwise: Yup.array().of(
      Yup.object().shape({
        uuid: Yup.string(),
        number: Yup.string(),
        companyName: Yup.string(),
        email: Yup.string().email('Invalid email'),
        firstName: Yup.string().matches(
          commonRegex.ALPHABETS_AND_SINGLE_SPACE,
          FIELD_INVALID_MESSAGES.firstName
        ),
        lastName: Yup.string().matches(
          commonRegex.ALPHABETS_AND_SINGLE_SPACE,
          FIELD_INVALID_MESSAGES.lastName
        ),
      })
    ),
  }),
  payoutInformation: Yup.string().when('payoutRecipients', {
    is: (recipients) => recipients.length > 1,
    then: Yup.string().required('Please provide purchase order information'),
  }),
  payoutRecipients: Yup.array().when('isResellerOrder', {
    is: true,
    then: Yup.array()
      .of(Yup.string())
      .test('Is not empty', 'Please select at least one', (val) => !isEmpty(val)),
    otherwise: Yup.array().of(Yup.string()),
  }),
});

const checkIncumbencySignerEmailClash = (value, context) => {
  if (!value) return true; // Skip validation for empty values
  const { options, parent } = context;
  const allSigners = options.context?.signers || []; // Access the signers array

  if (parent.signatureEntity === SIGNER_ENTITIES.INCUMBENCY) {
    // Check if another signer with a different entity (not guarantor) has the same email
    return !allSigners.some(
      (signer) =>
        signer?.signatureEntity !== SIGNER_ENTITIES.CORPORATE_GUARANTOR && // Exclude guarantors
        signer?.email?.toLowerCase() === value?.toLowerCase() && // Same email check
        signer !== parent // Exclude self-comparison
    );
  }
  return true; // Skip validation for non-incumbency emails
};

const checkGuarantorSignerNameMismatch = (value, context) => {
  if (!value) return true; // Skip validation for empty values
  const { parent, options, createError, path } = context;
  const allSigners = options.context?.signers || []; // Access the signers array

  if (parent?.signatureEntity === SIGNER_ENTITIES.CORPORATE_GUARANTOR) {
    // Check if any signer has the same email but different details
    const conflictingSigner = allSigners.find(
      (signer) =>
        signer?.email === value && // Same email check
        signer !== parent && // Exclude self-comparison
        ((signer?.firstName && // check if names exist and are different
          parent?.firstName &&
          signer?.firstName !== parent?.firstName) ||
          (signer?.lastName && parent?.lastName && signer?.lastName !== parent?.lastName))
    );

    if (conflictingSigner) {
      // Generate dynamic message with signer title
      const signerTitle = conflictingSigner?.label?.toLowerCase() || 'another signer';
      return createError({
        path: `${path}`,
        message: `The email input for ${signerTitle} and guarantor signer match. ${TEXT_CONSTANTS.GUARANTOR_SIGNER_MATCH_ERROR}`,
      });
    }
  }
  return true;
};

export const signersFormSchema = Yup.object().shape({
  signers: Yup.array().of(
    Yup.object().shape({
      firstName: nameSchema,
      lastName: nameSchema,
      jobTitle: Yup.string().required(TEXT_CONSTANTS.FIELD_REQUIRED_ERROR),
      email: Yup.string()
        .email(TEXT_CONSTANTS.INVALID_EMAIL_ERROR)
        .required(TEXT_CONSTANTS.FIELD_REQUIRED_ERROR)
        // Custom validation to check for email clashes between incumbency and authorized signers
        .test(
          'incumbency-email-clash',
          TEXT_CONSTANTS.INCUMBENCY_EMAIL_CLASH_ERROR,
          checkIncumbencySignerEmailClash
        )
        // Custom validation to check for user clash between guarantor and authorized signers
        .test('guarantor-email-match-field-mismatch', checkGuarantorSignerNameMismatch),
      phone: requiredPhoneFieldValidation,
    })
  ),
});

export const getNewCustomerAddressFormSchema = (
  selectedCustomer,
  multipleCountriesEnabled
) => {
  const requiredIfNewCustomer = () => {
    if (selectedCustomer?.isNewCustomer) {
      return Yup.string().required('This field is required');
    }
    return Yup.string();
  };
  const requiredIfNewCustomerAndMultipleCountriesEnabled = () => {
    if (selectedCustomer?.isNewCustomer && multipleCountriesEnabled) {
      return Yup.string().required('This field is required');
    }
    return Yup.string();
  };

  const getZipSchema = () => {
    if (selectedCustomer?.isNewCustomer) return zipSchema;
    return Yup.string();
  };

  return Yup.object().shape({
    street: requiredIfNewCustomer(),
    city: requiredIfNewCustomer(),
    state: requiredIfNewCustomer(),
    zip: getZipSchema(),
    country: requiredIfNewCustomerAndMultipleCountriesEnabled(),
    currency: requiredIfNewCustomerAndMultipleCountriesEnabled(),
  });
};

export const getOrderFormSchema = () => {
  return Yup.object().shape({
    companyNumber: Yup.string().required('This field is required'),
    message: Yup.string(),
    proposals: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required(),
          amount: Yup.number()
            .nullable()
            .when('orderType', {
              is: orderType.full_payment,
              then: Yup.number().nullable(),
              otherwise: Yup.number()
                .test({
                  name: 'required',
                  message: 'Amount is required',
                  test: (value) => !!value,
                })
                .test({
                  name: 'min',
                  message: 'Amount cannot be zero',
                  test: (value) => value && value > 0,
                }),
            }),
          orderType: Yup.string().nullable().required('This field is required'),
          startDate: Yup.date()
            .nullable()
            .when('orderType', {
              is: (selectedOrderType) => selectedOrderType === orderType.installments,
              then: Yup.date().nullable().required('Start Date is required'),
            }),
          endDate: Yup.date()
            .nullable()
            .when('orderType', {
              is: (selectedOrderType) => selectedOrderType === orderType.installments,
              then: Yup.date().nullable().required('End Date is required'),
            }),
          contractLength: Yup.number()
            .nullable()
            .when('orderType', {
              is: (selectedOrderType) => selectedOrderType === orderType.installments,
              then: Yup.number()
                .nullable()
                .required('Contract length is required')
                .min(0),
            }),
          paymentFrequency: Yup.string()
            .nullable()
            .when('orderType', {
              is: (selectedOrderType) => selectedOrderType === orderType.installments,
              then: Yup.string().nullable().required('Billing frequency is required'),
            }),
          paymentTerm: Yup.number().nullable().required('Net term is required'),
          vartanaFinancing: Yup.bool(),
          orderForm: Yup.array()
            .nullable()
            .required(ORDER_FORM_FIELD_ERROR_MESSAGE)
            .min(1, ORDER_FORM_FIELD_ERROR_MESSAGE),
          maxSubsidyAllowed: Yup.string(),
          reviewAndSignDocs: Yup.array().nullable().max(5, 'Max 5 documents are allowed'),
          subsidy: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          isDollar: Yup.bool().nullable(),
          interestRate: Yup.string().nullable(),
        })
      )
      .min(1, 'At least one proposal is required'),
  });
};

export const isProposalEmpty = (proposalObj) => {
  return Object.values(omit(proposalObj, ['key', 'title', 'vartanaFinancing'])).every(
    (val) => {
      return Array.isArray(val) ? val.length === 0 : !val;
    }
  );
};

export const getProposalTitle = (formValues) => {
  let updatedTitle = '';
  const {
    orderType: selectedOrderType,
    contractLength,
    paymentFrequency,
    paymentTerm,
  } = formValues;
  if (
    selectedOrderType === orderType.installments &&
    contractLength &&
    paymentFrequency
  ) {
    const roundedContractLength =
      typeof contractLength === 'number'
        ? formattedTermDuration(contractLength)
        : contractLength;
    updatedTitle = `Pay ${paymentFrequencyLabels[
      paymentFrequency
    ].toLowerCase()}, over ${roundedContractLength}`;
  } else if (
    selectedOrderType === orderType.pay_in_full &&
    paymentTerm !== null &&
    paymentTerm >= 0
  ) {
    updatedTitle = `Defer payment, with ${paymentTermLabels[paymentTerm]}`;
  } else if (selectedOrderType === orderType.full_payment) {
    updatedTitle = 'Full Payment';
  }
  return updatedTitle;
};

export const getProposalTitleV2 = (formValues) => {
  // This function is used to update the proposal title based on the form values
  // If the values aren't present, it will return an empty string
  let updatedTitle = '';
  const {
    orderType: selectedOrderType,
    contractLength,
    paymentFrequency,
    paymentTerm,
  } = formValues;
  if (selectedOrderType === orderType.full_payment) updatedTitle += 'Pay in full';
  if (selectedOrderType === orderType.installments && contractLength && paymentFrequency)
    updatedTitle += `${contractLength} ${paymentFrequency} payments`;
  if (updatedTitle && paymentTerm) updatedTitle += ` @ net-${paymentTerm}`;
  else if (updatedTitle && paymentTerm === 0) updatedTitle += ' upon receipt';
  return updatedTitle;
};

export const defaultFinancingInformationValues = {
  totalContractValue: '--',
  availableCreditAmount: null,
  availableCredit: '--',
  paymentTerm: '--',
  startEndDate: '--',
  paymentDue: '--',
  customerApplicationFee: '--',
  vendorApplicationFee: '--',
  paymentFrequencyLabel: null,
  paymentFrequency: '--',
  formattedBuyerFee: '--',
  formattedVendorFee: '--',
  netPayout: '--',
  totalFees: '--',
  isFullBuyout: false,
  payoffDiscount: '--',
  netPayoff: '--',
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  spiffAmount: '$0.00',
  spiffRate: '--',
};

export const checkIsVartanaFinancingAllowed = (
  availablePaymentOptions,
  isAppraisalApproved,
  isAppraisalExpired
) => {
  let isVartanaFinancingChecked = false;
  let isVartanaFinancingCheckBoxDisabled = false;

  if (!isAppraisalApproved || isAppraisalExpired) {
    return {
      isVartanaFinancingChecked,
      isVartanaFinancingCheckBoxDisabled,
    };
  }

  if (availablePaymentOptions.length === 1) {
    isVartanaFinancingCheckBoxDisabled = true;
    if (availablePaymentOptions[0] === paymentOptions.financing) {
      isVartanaFinancingChecked = true;
    } else if (availablePaymentOptions[0] === paymentOptions.direct) {
      isVartanaFinancingChecked = false;
    }
  } else {
    isVartanaFinancingChecked = true;
    isVartanaFinancingCheckBoxDisabled = false;
  }

  return {
    isVartanaFinancingChecked,
    isVartanaFinancingCheckBoxDisabled,
  };
};

export const getCloneOrderValues = (values, defaultProposalValues) => {
  return {
    ...values.proposals[0],
    key: uuidv4(),
    number: null,
    orderForm: null,
    reviewAndSignDocs: null,
    vartanaFinancing: defaultProposalValues.vartanaFinancing,
    title: '',
  };
};
