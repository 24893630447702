/**
 * TEXT_CONSTANTS contains key-value pairs for text used throughout the application.
 * This centralization allows for easy updates and ensures consistency in UI labels,
 * tooltips, and messages. It includes terms for forms, buttons, tooltips, and error messages
 */

export const TEXT_CONSTANTS = {
  PG_ACKNOWLEDGEMENT:
    'By checking this box, I acknowledge that I am providing personal information of the individual(s) that can act as the personal guarantor. Vartana will review their credit history through a soft credit check which will not impact their credit score.',
  GUARANTOR_SIGNER_MATCH_ERROR:
    'If these are the same individual, please ensure you enter the identical first name & last name.',
  INCUMBENCY_SIGNER_MATCH_ERROR:
    'The authorized signer and incumbency signer cannot be the same individual. Please select another contact.',
  TOO_SHORT_ERROR: 'Too short!',
  TOO_LONG_ERROR: 'Too long!',
  FIELD_REQUIRED_ERROR: 'This field is required',
  INVALID_EMAIL_ERROR: 'Invalid email address',
  INVALID_NAME_ERROR: 'Invalid name',
  INVALID_JOB_TITLE_ERROR:
    'To qualify as a guarantor, the signer must hold an executive-level title (e.g. CEO, CFO, or Director.) Please select another contact with the appropriate title.',
  INCUMBENCY_EMAIL_CLASH_ERROR:
    'The authorized signer and incumbency signer cannot be the same individual. Please select another contact.',
};
